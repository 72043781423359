import { formatAppNameText } from "@finbackoffice/fe-core";
import { CpfSignupProvider, useRuntimeConfig } from "@finbackoffice/site-core";
import Link from "next/link";
import { FC, useContext, useEffect, useRef } from "react";
import Img from "components/base/img/Img";
import LoginPanel from "components/header/login-panel/LoginPanel";
import LanguageDropdown from "components/header/language-dropdown/LanguageDropdown";
import UserPanel from "components/header/user-panel/UserPanel";
import ErrorBoundary from "components/base/error-boundary/ErrorBoundary";
import { HeaderContext } from "components/header/Header";
import ExchangeRatePanel from "components/header/exchange-rate/ExchangeRatePanel";
import HeaderNav from "components/header/nav/HeaderNav";
import { InitialDataContext } from "contexts";
import { LoginModal } from "components/header/LoginModal";
import SignupModal from "components/header/SignupModal";
import styles from "./v2.module.sass";

const V2: FC = () => {
    const COMMON_SITE_CONFIGS = useRuntimeConfig("COMMON_SITE_CONFIGS");
    const ASSETS_URL = useRuntimeConfig("ASSETS_URL");
    const { isUserLoggedIn, loginDefaultView, showExchangeRatePanel } = useContext(HeaderContext);
    const { setSiteHeaderHeight } = useContext(InitialDataContext);
    const ref = useRef<HTMLElement>(null);

    useEffect(() => {
        if (ref.current?.offsetHeight) {
            setSiteHeaderHeight(ref.current?.offsetHeight);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <header className={styles.header} ref={ref}>
            <Link href="/">
                <Img
                    source={`${ASSETS_URL}/${formatAppNameText(
                        COMMON_SITE_CONFIGS.appName,
                    )}/desktop/logo.svg`}
                    alt={`${COMMON_SITE_CONFIGS.appName} logo`}
                    title={`${COMMON_SITE_CONFIGS.appName} logo`}
                    width={0}
                    height={0}
                    style={{ width: "100%", height: "auto" }}
                />
            </Link>

            <HeaderNav />

            <div className={styles.rightPanel}>
                {isUserLoggedIn ? (
                    <ErrorBoundary name={UserPanel.name}>
                        <UserPanel />
                    </ErrorBoundary>
                ) : (
                    <>
                        <ErrorBoundary name={LoginPanel?.name}>
                            <LoginPanel />
                        </ErrorBoundary>
                        <CpfSignupProvider>
                            <SignupModal />
                        </CpfSignupProvider>
                        <ErrorBoundary name="LoginForm">
                            <LoginModal defaultView={loginDefaultView} />
                        </ErrorBoundary>
                    </>
                )}
                <div className="site-language-container">
                    {!isUserLoggedIn && showExchangeRatePanel && (
                        <ErrorBoundary name={ExchangeRatePanel.name}>
                            <ExchangeRatePanel />
                        </ErrorBoundary>
                    )}

                    <ErrorBoundary name={LanguageDropdown.name}>
                        <LanguageDropdown />
                    </ErrorBoundary>
                </div>
            </div>
        </header>
    );
};

export default V2;
